<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Courses Management" class="mb-3" />
      </b-col>
    </b-row>
    <data-create-popup title="Create Course"
      :schema="create_schema" :default-data="default_data"
      :create-data-fn="createItem" @create="$refs.group_list.getList()"
    />
    <data-table-ssr id="group_list" ref="group_list"
      :columns="fields" :get-list-fn="getList"
      :to-edit-fn="toEditPage"
    />
  </div>
</template>

<script>
import service from "../service";
import default_data from "../default_data"
const create_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Background', field: 'background', input_type: 'image', validate: { required: true } },
      { label: 'Web Background', field: 'web_background', input_type: 'image' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Title', field: 'title', validate: { required: true } },
      { label: 'Subtitle', field: 'subTitle', input_type:'ui-component', validate: { required: true } },
      { label: 'Key', field: 'key', validate: { required: true } },
    ]
  }
];

const fields = [
  { label: 'Background', field: 'background', input_type: 'image' },
  { label: 'Title', field: 'title' },
  { label: 'Subtitle', field: 'subTitle', display_type: 'translatable-text' },
  { label: 'Key', field: 'key' },
];
export default {

  data() {
    return {
      courses: [],
      default_data: {...default_data},
      create_schema,
      fields,
    };
  },

  created() {
  },

  methods: {
    async getList({ limit, page, query }) {
      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'courses-detail', params: { id: _id } });
    },
  },
};
</script>
