module.exports = {
    title: null,
    subTitle: {
      type: 'translatable-text',
      meta: {
        default_lang: 'vi'
      },
      value: '',
      translations: [
        {
          language: 'vi',
          value: ''
        }
      ]
    },
    background: null,
    web_background: null,
    key: null
}